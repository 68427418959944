<template>
  <div class="m-pg-sub">
    <page-cover code="18" layer="rgba(0,0,0,.3)">
      <!-- <h2 class="m-page-title">数据分析</h2> -->
      <!-- <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.journal.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div
      v-loading="loading"
      element-loading-text="数据分析中..."
      class="wrap">
      <Section title="IsoplotR" :showMore="false" class="section--img-click">
        <el-row v-if="res" :gutter="20">
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">detritals</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/detritals.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/?type=detritals')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">Pb-Pb</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/Pb-Pb.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/?type=Pb-Pb')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">Rb-Sr</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/Rb-Sr.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/?type=Rb-Sr')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">Sm-Nd</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/Sm-Nd.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/?type=Sm-Nd')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">U-Pb</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/U-Pb.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/?mathod=more')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">more</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <div class="more">
                  <el-button
                    type="primary"
                    style="height: 50px; font-size: 18px;"
                    @click="onOpenUrl('http://8.130.167.171:50055/?type=more')">
                    查看更多
                  </el-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </Section>
      <Section title="地化图解" :showMore="false" class="section--img-click">
        <el-row v-if="res" :gutter="20">
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">TAS</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/TAS.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/yjy/TAS.html')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">SiO2-K2O</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/SiO2-K2O.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/yjy/SiO2K2O.html')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">A/CNK</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/ACNK.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/yjy/ACNK.html')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">Pearce</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/Pearce.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/yjy/Pearce.html')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">2D Density</div>
                <div class="actions">
                  <!-- <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link> -->
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  src="/images/chart/2DDensity.jpg"
                  @click="onOpenUrl('http://8.130.167.171:50055/yjy/2ddensity.html')">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
        </el-row>
      </Section>
      <Section title="DetritalPy" :showMore="false">
        <el-row v-if="res" :gutter="20">
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">概率累计图</div>
                <div class="actions">
                  <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_gvljt)">下载</el-link>
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  :src="res.img_gvljt">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">直方图</div>
                <div class="actions">
                  <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_zft)">下载</el-link>
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  :src="res.img_zft">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">提琴图</div>
                <div class="actions">
                  <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_tqt)">下载</el-link>
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  :src="res.img_tqt">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chart-head">
                <div class="chart-head-title">二维图投点</div>
                <div class="actions">
                  <el-link :underline="false" icon="el-icon-download" @click="onDownload(res.img_ewt)">下载</el-link>
                </div>
              </div>
              <div class="chart-body">
                <el-image
                  fit="contain"
                  :src="res.img_ewt">
                  <template #error>
                    <img class="placeholder-img" src="@/assets/images/empty.png" />
                  </template>
                </el-image>
              </div>
            </div>
          </el-col>
          <!-- <el-col :span="8" style="margin-bottom: 20px;">
            <div class="chart" style="margin-top: 30px;">
              <div class="chart-body">
                <TabChat />
              </div>
            </div>
          </el-col> -->
        </el-row>
      </Section>
    </div>
  </div>
</template>

<script>
import * as sampleApi from "@/api/sampleAPI"
import setting from '@/setting'
import { downloadImage } from '@/utils'
import TabChat from './components/TabChat'
export default {
  data() {
    return {
      loading: false,
      res: null
    }
  },
  components: {
    TabChat
  },
  methods: {
    getAnalysisImg: async function(sampleIds) {
      this.loading = true
      let data = await sampleApi.getDataAnalysisImg(sampleIds)
      .catch(() => {
        this.res = null
      }).finally(() => {
        this.loading = false
      })
      if (data.msg !== 'ok') {
        this.res = null
        return
      }
      let baseUrl = setting.baseUrl.img
      data.img_gvljt = data.img_gvljt ? baseUrl + data.img_gvljt : ''
      data.img_zft = data.img_zft ? baseUrl + data.img_zft : ''
      data.img_ewt = data.img_ewt ? baseUrl + data.img_ewt : ''
      data.img_tqt = data.img_tqt ? baseUrl + data.img_tqt : ''
      this.res = data
    },
    onOpenUrl: function(url) {
      window.open(url)
    },
    onDownload: function(url) {
      if (!url) {
        return
      }
      let arr = url.split('/')
      let filename = arr[arr.length - 1]
      downloadImage(url, filename)
    }
  },
  mounted() {
    let sampleIds = this.$route.query?.sampleIds.split(',')
    if (sampleIds.length) {
      this.getAnalysisImg(sampleIds)
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: calc(100vh - 100px);
  /deep/ .section {
    &-head {
      .title {
        font-weight: bold;
        color: #1A76D1;
      }
    }
    &-body {

    }
    &--img-click {
      /deep/ .el-image__inner {
        cursor: pointer;
      }
    }
  }
  .chart {
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    &-head  {
      padding: 20px;
      &-title {
        font-weight: bold;
      }
      .actions {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;
      }
    }
    &-body {
      position: relative;
      padding: 12px;
      height: 305px;
      border-radius: 4px;
      overflow: hidden;
      .el-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        i {
          font-size: 80px;
        }
        .placeholder-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .more {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-icon {
          margin-bottom: 40px;
          color: #409EFF;
          font-size: 80px;
        }
      }
      &:hover {
        .actions {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
</style>

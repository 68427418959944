<template>
  <div id="box">
    <el-tabs tab-position="left" style="height: 400px" class="demo-tabs" v-model="currentTab">
      <el-tab-pane label="detritals">
        <template #label>
          <el-button type="primary" :plain="currentTab !== '0'" class="my-button">
            <div class="button-icon button-icon1"></div>
            detritals
          </el-button>
        </template>
        <img class="imgbutton" src="./images/detritals2024.png" alt="" @dblclick="toIsoplotr('detritals')">
      </el-tab-pane>

      <el-tab-pane label="Pb-Pb">
        <template #label>
          <el-button type="primary" :plain="currentTab !== '1'" class="my-button">
            <div class="button-icon button-icon2"></div>
            Pb-Pb
          </el-button>
        </template>
        <img class="imgbutton" src="./images/pbpb2024.png" alt="" @dblclick="toIsoplotr('Pb-Pb')">
      </el-tab-pane>

      <el-tab-pane label="Rb-Sr">
        <template #label>
          <el-button type="primary" :plain="currentTab !== '2'" class="my-button">
            <div class="button-icon button-icon3"></div>
            Rb-Sr
          </el-button>
        </template>
        <img class="imgbutton" src="./images/rbsr2024.png" alt="" @dblclick="toIsoplotr('Rb-Sr')">
      </el-tab-pane>

      <el-tab-pane label="Sm-Nd">
        <template #label>
          <el-button type="primary" :plain="currentTab !== '3'" class="my-button">
            <div class="button-icon button-icon4"></div>
            Sm-Nd
          </el-button>
        </template>
        <img class="imgbutton" src="./images/smnd2024.png" alt="" @dblclick="toIsoplotr('Sm-Nd')">
      </el-tab-pane>

      <el-tab-pane label="U-Pb">
        <template #label>
          <el-button type="primary" :plain="currentTab !== '4'" class="my-button">
            <div class="button-icon button-icon5"></div>
            U-Pb
          </el-button>
        </template>
        <img class="imgbutton" src="./images/upb2024.png" alt="" @dblclick="toIsoplotr('U-Pb')">
      </el-tab-pane>

      <el-tab-pane label="more">
        <template #label>
          <el-button type="primary" :plain="currentTab !== '5'" class="my-button" @click.stop="toIsoplotr('more')">
            <div class="button-icon button-icon6"></div>
            more
          </el-button>
        </template>

      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { ref } from 'vue';

let currentTab = ref("0")

function toIsoplotr(mathodName) {
  console.log(mathodName);
  window.open("http://8.130.167.171:50055/?mathod="+mathodName)
}

</script>

<style lang="less" scoped>
#box {
  padding: 20px 0;
}
#box button {
  padding: 8px 15px;
  width: 105px;
}

.imgbutton {
  width: 400px;
  height: 400px;
}

.button-icon {
  display: inline-block;
  background-size: 100% 100%;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.my-button {
  text-align: left;
  border-radius: 4px;
}
::v-deep .my-button > span {
  display: flex;
  align-items: center;
}


.el-button--primary.is-plain .button-icon1 {
  background-image: url('./images/detritals.png');
}

.my-button:hover .button-icon1,
.el-button--primary .button-icon1 {
  background-image: url('./images/detritals_.png');
}


.el-button--primary.is-plain .button-icon2 {
  background-image: url('./images/pbpb.png');
}

.my-button:hover .button-icon2,
.el-button--primary .button-icon2 {
  background-image: url('./images/pbpb_.png');
}

.el-button--primary.is-plain .button-icon3 {
  background-image: url('./images/rbsr.png');
}

.my-button:hover .button-icon3,
.el-button--primary .button-icon3 {
  background-image: url('./images/rbsr_.png');
}

.el-button--primary.is-plain .button-icon4 {
  background-image: url('./images/smnd.png');
}

.my-button:hover .button-icon4,
.el-button--primary .button-icon4 {
  background-image: url('./images/smnd_.png');
}

.el-button--primary.is-plain .button-icon5 {
  background-image: url('./images/upb.png');
}

.my-button:hover .button-icon5,
.el-button--primary .button-icon5 {
  background-image: url('./images/upb_.png');
}


.el-button--primary.is-plain .button-icon6 {
  background-image: url('./images/more.png');
}

.my-button:hover .button-icon6,
.el-button--primary .button-icon6 {
  background-image: url('./images/more_.png');
}

/* :deep(.el-icon-detritals) {
  background: url('./images/detritals.png') center no-repeat;
  background-size: 100% 100%;
  width: 10px;
  height: 10px;
} */

::v-deep .el-icon-detritals:before {
  content: "替";
  font-size: 20px;
  visibility: hidden;
}

::v-deep .el-icon-detritals {
  font-size: 16px;
}

::v-deep .el-icon-detritals:before {
  content: "\e611";
}
</style>